import React from 'react';
import { postsStyle } from '../assets/jss/pages/postsStyle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DefaultHeader } from '../components/Header/Header';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Button } from '../components/Button/Button';

const useStyles = makeStyles(postsStyle);

const CookiePage: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.blogPostPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'cookies.title' })}</title>
      </Helmet>
      <DefaultHeader />

      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.cookies" defaultMessage="Cookies" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              <FormattedMessage id="cookies.title" defaultMessage="Cookies" />
            </Typography>
            <Button color="info" onClick={() => window.cookiehub.openSettings()}>
              <FormattedMessage id="cookies.settings" defaultMessage="Cookies Setting" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CookiePage;
